<template>
  <div>
    <div
      class="
        uk-section uk-padding-remove-vertical uk-margin-remove-vertical
        in-equity-breadcrumb in-equity-breadcrumb-green
      "
    >
      <div class="uk-container">
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <ul class="uk-breadcrumb">
              <li>
                <router-link :to="{ name: 'guest.home' }">Home</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'guest.signals' }"
                  >Signals</router-link
                >
              </li>
              <li>
                <span
                  >{{ plan.months }} Month{{
                    plan.months == 1 ? "" : "s"
                  }}</span
                >
              </li>
              <li><span>Payment</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      class="uk-section uk-padding-remove-vertical uk-margin-remove-vertical"
    >
      <div class="uk-container uk-margin-top uk-padding">
        <h2 class="uk-margin-remove">Subscribe To Signals</h2>
        <p class="uk-margin-remove">Community members get signals for FREE!</p>
        <p class="uk-margin-remove">You don't need to subscribe  for signals if you intend to join the Community.</p>
        <hr>
        <div
          class="uk-child-width-1-1 uk-child-width-1-3@m uk-grid"
          data-uk-grid=""
        >
          <div class="uk-first-column uk-background-default uk-margin-right uk-padding">
            <h4>Payment Details</h4>
            <table class="uk-table uk-table-divider">
              <tbody>
                <tr>
                  <td>Package</td>
                  <td>{{ plan.months }} Month{{
                    plan.months == 1 ? "" : "s"
                  }}</td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td>$ {{ plan.amount }}</td>
                </tr>
                <tr v-if="plan.discount != null">
                  <td>Discount</td>
                  <td>{{ plan.discount }} % Applied</td>
                </tr>
                <tr v-if="subscription != null">
                  <td>Your Current Subscription Expiry</td>
                  <td>{{ subscription.expiry }}</td>
                </tr>
                <tr v-if="subscription != null">
                  <td colspan="2"><div class="uk-alert uk-alert-info">Extending your membership will add the selected months to your current subscription expiry</div></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="uk-background-default uk-padding uk-width-1-2@m">
            <div class="uk-row-first">
              <payment
                type="signals"
                :id="$route.params.id"
                @received="paymentReceived"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();

    if (this.user.is_subscribed_to_signals) {
      this.fetchSub();
    }
  },

  data() {
    return {
      plan: {},
      subscription: null
    };
  },

  methods: {
    fetch() {
      this.$loader.start("full");
      this.$axios
        .get(`/api/v1/guest/signals/subscribe/${this.$route.params.id}`)
        .then((response) => {
          this.$loader.stop();
          this.plan = response.data.plan;
        });
    },

    fetchSub() {
      this.$loader.start("full");
      this.$axios.get(`/api/v1/guest/signals/subscription`).then((response) => {
        this.$loader.stop();
        this.subscription = response.data.plan;
      });
    },

    paymentReceived() {
      this.$router.push({ name: 'guest.signals' })
    },
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },
  },
};
</script>
